<template>
  <div class="home">
    <van-nav-bar :title="title" @click-left="back" left-text="返回" fixed left-arrow style="z-index: 20"/>
    <div style="margin-top: 1.22667rem"></div>
    <div style="box-sizing: border-box;">
        <preview-pdf ref="previewPdfh5"></preview-pdf>
    </div>
  </div>
</template>
 
<script>
import PreviewPdf from './pdf.vue';
export default {
  name: 'Home',
  components: {
    PreviewPdf
  },
  data () {
    return {
        title: ''
    }
  },
  mounted () {
    const urls = this.$route.query.url
    const name = this.$route.query.name
    let namess = name.split('.')[0]
    let titless = ''
    if(namess.length > 6) {
        titless = namess.substring(0, 6) + '...'
    } else {
        titless = namess
    }
    this.title = titless
    console.log(urls)
    this.$refs.previewPdfh5.openPdf('https://mobworktime.ttkuaiban.com/upload/834b655854f54a34b1d367d9765bcf11.pdf')
    // this.$refs.previewPdfh5.openPdf(urls)
  },
  methods: {
    // 返回
    back() { 
        history.back();
    },
  } 
}
</script>