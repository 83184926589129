<template>
  <div class="pdfBox">
    <div id="previewPdf"></div>
  </div>
</template>
<script>
import pdfh5 from 'pdfh5';
import 'pdfh5/css/pdfh5.css'
export default {
  name: 'pdfh5',
  data () {
    return {
      pdfh5: null,
    }
  },
  methods: {
    openPdf(url){     //url：PDF文件地址
      this.pdfh5 = new pdfh5('#previewPdf', {
        pdfurl: url
      });
      this.pdfh5.on('success', ()=>{
        console.log('pdf渲染成功');
      });
    }
  }
}
</script>
<style scoped>
.pdfBox {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 94vh;
  background: #000;
  overflow: hidden;
  z-index: 99;
  box-sizing: border-box;
  margin-top: 1.22667rem;
}
#previewPdf {
    height: 100%;
  }
</style>